import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
const App = lazy(() => import('./App'));

const renderLoader = () => <Spinner />;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={renderLoader()}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
